import { AddFunds, GiftBox, Taxes } from 'components/DesignSystemIcons'
import { Box } from 'design-system/box'
import * as DS from 'design-system/buttons'
import { useDeals } from 'hooks/useDeals'
import { useRouter } from 'next/router'
import { DealBadge } from 'screens/my-joe/sections/RewardMemberships/components'
import { styled } from 'styles/stitches.config'
import { logError } from 'utils/honeybadger'
import { formatMoney } from 'utils/money'

const PillButton = styled(DS.PillButton, {
  flexGrow: 1,
  color: '$grey800',
})

type Props = {
  companyId: string
  storeId: string
  balance: number | null
}

export const StoreActions = ({ companyId, storeId, balance }: Props) => {
  const router = useRouter()
  const { hasDeals } = useDeals(companyId)

  const handleClick = (sendAsGift?: boolean) => () => {
    router
      .push({
        pathname: '/gift-cards/purchase/[companyId]',
        query: {
          companyId,
          storeId,
          sendAsGift,
        },
      })
      .catch(logError)
  }

  return (
    <Box flex alignItems="center" css={{ gap: '$s', padding: '$xs $s' }}>
      <PillButton onClick={handleClick(true)}>
        <GiftBox size={20} />
        Gift
      </PillButton>
      <PillButton
        onClick={handleClick()}
        css={{ fontWeight: '$bold', flexGrow: '1.5' }}
      >
        <Taxes size={20} />
        {balance ? formatMoney(balance, 2) : '$ - . --'}
      </PillButton>
      <PillButton onClick={handleClick()}>
        <AddFunds size={20} />
        Funds
        {hasDeals && <DealBadge />}
      </PillButton>
    </Box>
  )
}
