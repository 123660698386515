import { styled } from 'styles/stitches.config'
import { H1 } from 'design-system/headings'

export const MastheadGroup = styled('div', {
  alignItems: 'center',
  color: '$grey1000',
  display: 'flex',
  flex: '1 0 auto',
  maxWidth: '100%',
  padding: '0 $xxs',
})

export const MastheadIcon = styled('button', {
  alignItems: 'center',
  color: '$grey1000',
  display: 'flex',
  flex: '0 0 auto',
  height: 28,
  justifyContent: 'center',
  margin: '0 $xxs',
  width: 28,
})

export const MastheadTitle = styled(H1, {
  color: '$grey1000',
  flex: 1,
  fontSize: '$heading1',
  fontWeight: '$bold',
  lineHeight: 'heading1',
  overflow: 'hidden',
  padding: '0 $xxs',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  variants: {
    center: {
      true: {
        textAlign: 'center',
      },
    },
    small: {
      true: {
        fontSize: '$body1',
        fontWeight: '$bold',
        lineHeight: '$body1',
      },
    },
  },
})
