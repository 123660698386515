import { Aura, Star, StarEmpty } from 'components/DesignSystemIcons'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { styled } from 'styles/stitches.config'
import useEventErrorHandler from 'hooks/useEventErrorHandler'
import { CSS } from '@stitches/react'
import { useSWRMutation } from 'hooks/useSWR'
import { ApiStatus } from 'types/api'
import { useAuth } from 'hooks/useAuth'

export const FavoriteIconAura = styled(Aura, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 36,
  height: 36,
  boxShadow: '$storeCard',
  backgroundColor: '$grey100',
  border: '2px solid $white',
  color: '$grey600',

  variants: {
    filled: {
      true: {
        background: '$yellow600',
        color: '$white',
      },
    },
    small: {
      true: {
        width: 24,
        height: 24,
      },
    },
  },
})

interface Props {
  storeId: string
  like?: boolean
  small?: boolean
  css?: CSS
}

const FavoriteButton = ({
  storeId,
  css,
  like: initLike = false,
  small = false,
}: Props) => {
  const [like, setLike] = useState(initLike)
  const isAuth = useAuth()
  const router = useRouter()
  const iconSize = small ? 16 : 20

  const Icon = like ? Star : StarEmpty

  const { handleError } = useEventErrorHandler()

  const onSuccess = (like: boolean) => (data: ApiStatus) => {
    // istanbul ignore else
    if (data.success) {
      setLike(like)
    }
  }

  const { trigger: unlikeStore, isMutating: isUnLiking } = useSWRMutation(
    'unlikeStore',
    { onSuccess: onSuccess(false), onError: handleError }
  )

  const { trigger: likeStore, isMutating: isLiking } = useSWRMutation(
    'likeStore',
    { onSuccess: onSuccess(true), onError: handleError }
  )

  const handleClick = async (event: React.MouseEvent) => {
    event.stopPropagation()

    if (!isAuth) {
      router.push('/sign-in/getting-started')
      return
    }

    if (isUnLiking || isLiking) {
      return
    }

    if (like) {
      unlikeStore({ storeId })
    } else {
      likeStore({ storeId })
    }
  }

  return (
    <FavoriteIconAura
      as="button"
      css={css}
      small={small}
      filled={like}
      onClick={handleClick}
      aria-label={like ? 'Unlike' : 'Like'}
    >
      <Icon size={iconSize} />
    </FavoriteIconAura>
  )
}

export default FavoriteButton
